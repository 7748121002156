@import 'src/styles/variables/global';
@import 'src/styles/variables/colors';
@import 'src/styles/variables/mixins';

.button {
  display: flex;
  background: none;
  min-width: 7.5em;
  align-items: center;
  padding: 0.52em 1em;
  justify-content: center;
  transition: all $time-default;
  border-radius: $border-radius;
  font: 1.125rem / 1.54em $main-font-bold;

  @include media(phone-small) {
    font-size: 0.875rem;
  }
}

.withEnhancerStart {
  margin-inline-start: 0.75rem;
}

.withEnhancerEnd {
  margin-inline-end: 0.75rem;
}

.buttonContent {
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    width: 1rem;
    height: 1rem;
  }
}

.primary {
  @extend .button;
  background: $color-primary;
  stroke: $color-white;
  color: $color-white;

  &:hover {
    background: darken($color-primary, 10%);
  }

  a {
    color: $color-white;
  }
}

.secondary {
  @extend .button;
  color: $text-color;
  stroke: $text-color;
  padding-inline-start: 0;
  padding-inline-end: 0;
}

.tertiary {
  @extend .button;
  color: $color-primary;
  background-color: $color-white;
  border: 0.0625rem solid currentColor;

  a {
    color: $color-primary;
  }
}

.disabled {
  cursor: not-allowed;
  background: $color-gray;
  color: $color-white;
  stroke: $color-white;
  box-shadow: none;

  &:hover {
    background: $color-gray;
    color: $color-white;
    box-shadow: none;
  }
}

.vip-primary {
  @extend .primary;
  background-color: $vip-primary-color;

  &:hover {
    background-color: $vip-primary-color;
  }
}

.vip-secondary {
  @extend .secondary;
  color: $vip-primary-color;
}

.vip-tertiary {
  @extend .tertiary;
  color: $vip-primary-color;
}

.noBgr {
  background-color: unset;
}

.vipOverrides {
  border-radius: 6.25rem;
}

.vipDisabled {
  cursor: not-allowed;
  box-shadow: none;
  background-color: rgba($vip-primary-color, 0.5);

  &:hover {
    background-color: rgba($vip-primary-color, 0.5);
  }
}
