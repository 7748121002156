@mixin media($media, $orientation: false) {
  @if $orientation == 'landscape' {
    @if $media == phone-small {
      @media only screen and (max-width: 667px) and (orientation: landscape) {
        @content;
      }
    }

    @if $media == phone-large {
      @media only screen and (max-width: #{$phone-large - 1px}) and (orientation: landscape),
      only screen and (max-height: 480px) and (max-width: 853px),
      only screen and (max-height: 414px) and (max-width: 896px),
      only screen and (max-height: 375px) and (max-width: 812px) {
        @content;
      }
    }

    @if $media == tablet {
      @media only screen and (min-width: $phone-large) and (max-width: #{$tablet-max - 1}) and (orientation: landscape) {
        @content;
      }
    }
  } @else {
    @if $media == phone-small {
      @media only screen and (max-width: $phone-small) {
        @content;
      }
    }

    @if $media == phone-large {
      @media only screen and (max-width: #{$phone-large - 1px}),
      only screen and (max-height: 480px) and (max-width: 853px),
      only screen and (max-height: 414px) and (max-width: 896px),
      only screen and (max-height: 375px) and (max-width: 812px) {
        @content;
      }
    }

    @if $media == tablet {
      @media only screen and (min-width: $phone-large) and (max-width: #{$tablet-max - 1}) {
        @content;
      }
    }

    @if $media == laptop {
      @media only screen and (min-width: #{$laptop-max - 1}) {
        @content;
      }
    }

    @if $media == desktop {
      @media only screen and (min-width: #{$desktop-max - 1}) {
        @content;
      }
    }
  }
}
