@import 'src/styles/variables/global';

button.button {
  pointer-events: none;
}

.appearAnimation {
  animation: showSpinner $time-default;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes showSpinner {
  0% {
    max-width: 0;
  }

  100% {
    max-width: 1rem;
  }
}
