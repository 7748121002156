@import 'src/styles/variables/colors';

$animation-duration: 1.4s;

.root {
  display: inline-block;
  animation: circularRotate $animation-duration linear infinite;
  font: icon;
}

.circle {
  stroke: $color-primary;
  animation: circularDash $animation-duration ease-in-out infinite;
  stroke-dasharray: 80px, 200px;
  stroke-dashoffset: 0;
  box-sizing: inherit;

  &.vip-primary {
    stroke: $vip-primary-color;
  }

  &.primary {
    stroke: $color-primary;
  }

  &.white {
    stroke: $color-white;
  }

  &.red {
    stroke: $color-red;
  }

  &.green {
    stroke: $color-green;
  }

  &.dark {
    stroke: $color-dark-blue;
  }
}

@keyframes circularRotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes circularDash {
  0% {
    stroke-dasharray: 0, 200px;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px;
  }

  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -125px;
  }
}
