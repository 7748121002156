@import 'src/styles/variables/global';
@import 'src/styles/variables/colors';
@import 'src/styles/variables/elements';

$success-first-layer-color: #e2ffe0;
$success-second-layer-color: #f3fff2;
$fail-first-layer-color: #fcdcdc;
$fail-second-layer-color: #fef0f0;
$warning-first-layer-color: #f5f2dc;
$warning-second-layer-color: #fefdeb;
$pending-first-layer-color: #fefdeb;
$pending-second-layer-color: #f9f6ef;

.root {
  align-items: center;
  width: 40.75rem;
  display: flex;
  position: relative;

  @include media(phone-large) {
    width: calc(100vw - #{$container-indent-phone} * 2);
  }

  &::after,
  &::before {
    height: calc(100% - 1.5rem);
    width: 0.3125rem;
  }
}

.container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  padding: 2rem;
  width: 100%;
  transition: all $time-smooth linear;
}

.heading {
  display: flex;
  align-items: flex-start;
}

.icon {
  display: flex;
  padding: 0.313rem;
  border-radius: 50%;

  svg {
    padding: 0.1rem;
    border-radius: 50%;
  }
}

.successContainer {
  @extend .icon;
  background-color: $success-second-layer-color;
}

.success {
  background-color: $success-first-layer-color;
}

.failContainer {
  @extend .icon;
  background-color: $fail-second-layer-color;
}

.fail {
  @extend .icon;
  background-color: $fail-first-layer-color;
}

.warningContainer {
  @extend .icon;
  background-color: $warning-second-layer-color;
}

.warning {
  background-color: $warning-first-layer-color;
}

.pendingContainer {
  @extend .icon;
  background-color: $pending-second-layer-color;
}

.pending {
  background-color: $pending-first-layer-color;
}

.close {
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 1rem;
  right: 1rem;

  path,
  circle {
    stroke: $vip-black;
  }
}

.title {
  margin-top: 1.5rem;
  font: 1.8rem / 2.8rem $main-font-bold;
  color: $vip-black;
}

.description {
  font: 1.125rem / 1.9375rem $main-font-roman;
  margin-top: 1rem;
  color: $vip-grey;
}

.actionsContainer {
  display: grid;
  column-gap: 3rem;
  row-gap: 1rem;
  grid-auto-flow: column;
  grid-auto-columns: minmax(0, 1fr);
  width: 100%;
  margin-top: 2rem;

  @include media(phone-large) {
    grid-auto-flow: row;
  }
}

[dir='rtl'] {
  .close {
    right: unset;
    left: 1rem;
  }
}
