@import 'src/styles/variables/colors';
@import 'src/styles/variables/global';
@import 'src/styles/variables/functions';

@keyframes vipSuspensePreload {
  0% {
    background-color: $color-white;
    box-shadow: 0 0 2rem 0.25rem $color-white;
  }

  50% {
    background-color: rgba($vip-primary-color, 0.1);
    box-shadow: 0 0 2rem 0.25rem rgba($vip-primary-color, 0.15);
  }

  100% {
    background-color: $color-white;
    box-shadow: 0 0 2rem 0.25rem $color-white;
  }
}

@keyframes suspensePreload {
  0% {
    background-color: $color-white;
    box-shadow: 0 0 2rem 0.25rem $color-white;
  }

  50% {
    background-color: rgba($color-primary, 0.1);
    box-shadow: 0 0 2rem 0.25rem rgba($color-primary, 0.15);
  }

  100% {
    background-color: $color-white;
    box-shadow: 0 0 2rem 0.25rem $color-white;
  }
}

@keyframes appearAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.root {
  opacity: 1;
  width: 100%;
  height: 100%;
  animation: appearAnimation $time-smooth ease-in forwards;
}

.rootWholeView {
  @extend .root;
  height: vh-without-url-bar(100);
  width: 100vw;
}

.container {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  animation: suspensePreload $time-smooth * 2 linear infinite forwards;
  justify-content: center;
  align-items: center;
  display: flex;
}

.isVip .container {
  animation-name: vipSuspensePreload;
}

.icon {
  width: 30%;
}
