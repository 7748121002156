@import '../variables/global';

@keyframes visibleIn {
  0% {
    visibility: hidden;
  }

  100% {
    visibility: visible;
  }
}

%visibleIn {
  animation: visibleIn $time-smooth forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

%fadeIn {
  animation: fadeIn $time-smooth forwards;
}

@keyframes fadeInBottom {
  from {
    opacity: 0;
    transform: translate3d(0, -50%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

%fadeInBottomSmooth {
  animation: fadeInBottom $time-smooth forwards;
}

%fadeInBottomSlow {
  animation: fadeInBottom $time-slow forwards;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 50%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUpSmall {
  from {
    opacity: 0;
    transform: translate3d(0, 1%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

%fadeInUpSmall {
  animation: fadeInUpSmall $time-smooth forwards;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -50%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-5%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

%fadeInLeftSmooth {
  animation: fadeInLeft $time-smooth forwards;
}

%fadeInLeftSlow {
  animation: fadeInLeft $time-slow forwards;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(5%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

%fadeInRightSmooth {
  animation: fadeInRight $time-smooth forwards;
}

%fadeInRightSlow {
  animation: fadeInRight $time-slow forwards;
}

@keyframes dontDisplayImmediately {
  0% {
    opacity: 0;
  }

  99% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
