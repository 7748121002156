@import 'src/styles/variables/functions';
@import 'src/styles/variables/animation';

.container {
  animation: fadeIn $time-smooth ease-in forwards;
  height: vh-without-url-bar(100);
  width: 100vw;
  opacity: 0;
  display: flex;
  align-items: center;
}
