:root {
  --browser-context: 16px;
}

// global variables
$browser-context: var(--browser-context) !default;
$border-radius: 0.5rem !default;

// VIP global variables
$vip-border-radius: 1rem;

// animation variables
$time-default: 0.3s !default;
$time-fast: $time-default * 0.5 !default;
$time-smooth: $time-default * 3 !default;
$time-slow: $time-default * 5 !default;

$z-index: (
  hidden: -1,
  default: 1,
  select-bar: 10001,
  header: 10002,
  chatbot: 10003,
  footer: 10004,
  swipe-up-modal: 10004,
  message-bar: 10005,
  sidebar: 10006,
  modal: 10500,
  popper: 11000,
  tooltip: 15000,
  search-suggestion-dropdown: 10005,
);

// media queries
$phone-small: 375px;
$phone-large: 768px;
$tablet-max: 1366px;
$laptop-max: 1600px;
$desktop-max: 1920px;

// container size
$container-indent: 2.4375rem;
$container-indent-phone: 4vw;
$container-indent-tablet: 2rem;

$container-phone: calc(100% - #{$container-indent-phone} * 2);
$container-tablet: calc(100% - #{$container-indent-tablet} * 2);
$container-desktop: calc(100% - #{$container-indent} * 6);
$container-laptop: calc(100% - #{$container-indent} * 3);
$container: calc(100% - #{$container-indent} * 2);

// VIP container size
$vip-container-indent: 3.4375rem;

$vip-container: calc(100% - #{$vip-container-indent} * 2);

// variables
$phone-block-indent: 1.5rem;
$app-content-margin: 2.1875rem;
$box-shadow-margin: 0.8125rem; // avoid cutting the table shadow with the overflow property

$header-height: 6rem;
$header-height-tablet: 5rem;
$header-height-mobile: 4rem;

// VIP variables
$vip-app-content-margin: 1rem;

$vip-header-height: 4.5rem;
$vip-footer-height: 4.25rem;

$vip-sidebar-width: 16rem;
$vip-sidebar-width-collapsed: 4.5rem;

$vip-navigation-height: 6.875rem;
$vip-navigation-height-mobile: 5rem;

// Frutiger
$main-font-light: 'Frutiger Light'; // 300
$main-font-roman: 'Frutiger Roman'; // 400
$main-font-bold: 'Frutiger Bold'; // 700
$main-font-black: 'Frutiger Black'; // 900

// Tahoma
$tahoma-regular: 'Tahoma Regular';
$tahoma-bold: 'Tahoma Bold';
