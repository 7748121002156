@import 'src/styles/variables/global';
@import 'src/styles/variables/colors';
@import 'src/styles/variables/mixins';
@import 'src/styles/variables/elements';

.subtitle {
  font: 1.5rem / 1.86rem $main-font-bold;

  @include media(phone-large) {
    font-size: 1.35rem;
  }
}

.description {
  font: 1rem / 1.5rem $main-font-roman;
  color: $color-black;
  margin-top: 0.5rem;
}

.closeIcon {
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.languageModal {
  @extend %invisibleScroll;
  padding: 3.5rem 2.5rem 2.5rem;
  width: 45.3125rem;
  max-height: 75dvh;
  overflow-y: auto;
  max-width: 75vw;
}

.radioContainer {
  width: 100%;
  margin-top: 1.5rem;
  row-gap: 1.5rem;
  display: grid;
}

.radioItem {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;

  &:not(:last-child) {
    border-bottom: 0.0625rem solid $border-color;
    padding-bottom: 1rem;
  }

  &:hover,
  &.selected {
    .label {
      color: $color-primary;

      &.labelVip {
        color: $vip-primary-color;
      }
    }
  }

  &.selected input {
    opacity: 1;
  }
}

.labelContainer {
  row-gap: 0.25rem;
  display: grid;
}

.label {
  font: 1.125rem / normal $main-font-roman;
  transition: color $time-default linear;
  color: $vip-dark-grey;
}

.subLabel {
  font-family: $main-font-roman;
  color: $color-grey;
}

.input {
  margin: 0;
  opacity: 0.3;
  width: 1.5rem;
  height: 1.5rem;
  accent-color: $color-primary;
  pointer-events: none;
  cursor: pointer;

  &.inputVip {
    accent-color: $vip-primary-color;
  }
}

.buttonContainer {
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  align-items: center;
  column-gap: 2.5rem;
  margin-top: 4rem;
  display: grid;

  @include media(phone-large) {
    grid-template-columns: 1fr;
    row-gap: 1.5rem;
  }
}
