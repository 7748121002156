@import 'src/styles/variables/global';
@import 'src/styles/variables/mixins';

#labibaWebBotDiv {
  * {
    font-family: $main-font-roman !important;
  }

  #chatcomposer {
    border-radius: 1.5625rem;
    margin-top: 0;

    > div,
    > div > div {
      height: 2rem;
    }
  }

  .e7t7c040 {
    left: unset !important;
    bottom: 2.4063rem !important;
    right: calc(#{$container-indent-tablet} + 10rem) !important;
    z-index: map-get($z-index, chatbot) !important;

    @include media(phone-large) {
      right: calc(#{$container-indent-phone} + 10rem) !important;
    }

    @include media(desktop) {
      right: calc(#{$container-indent} + 10rem) !important;
    }
  }

  .eq1nrcm0 {
    left: 0 !important;
    right: unset !important;
    width: 9rem !important;
    height: 2.8rem !important;
  }

  .ep7mz240 {
    bottom: 0 !important;
    right: -9rem !important;
    left: unset !important;

    @media only screen and (max-width: 490px) {
      left: 0 !important;
    }
  }
}

#minimizedChat {
  left: 0 !important;
  bottom: 0 !important;

  img {
    margin: 0 !important;
    right: 0 !important;
    left: 0 !important;
    top: 0 !important;
    width: 100% !important;
    height: 100% !important;
  }
}

[dir='rtl'] {
  #labibaWebBotDiv {
    .e7t7c040 {
      right: unset !important;
      left: calc(#{$container-indent-tablet} + 10rem) !important;

      @include media(phone-large) {
        left: calc(#{$container-indent-phone} + 10rem) !important;
      }

      @include media(desktop) {
        left: calc(#{$container-indent} + 10rem) !important;
      }
    }

    .eq1nrcm0 {
      right: 0 !important;
      left: unset !important;
    }

    .ep7mz240 {
      left: unset !important;
      right: -16rem !important;

      @media only screen and (max-width: 490px) {
        right: 0 !important;
      }
    }
  }

  #minimizedChat {
    right: 0 !important;
    left: unset !important;
  }
}
