// main colors
$color-white: #fff;
$color-black: #052b2f;

$color-primary: #018e9e;
$color-secondary: #e3f2f8;
$color-tertiary: #edf3f5;

// secondary colors
$highlight: rgba(0, 0, 0, 0);
$color-light-blue: #f2fbff;
$color-dark-blue: #14415a;
$color-silver: #c0c0c0;
$color-green: #5d9f58;
$color-gray: #b3b2b3;
$color-red: #c60000;
$color-timeline-gray: #e6e6e6;
$color-light-green: #58c88c;
$color-dark-yellow: #cc9a27;

// upload files DnD element colors
$remove-fill-color: #fdd;
$refresh-fill-color: #d9edf5;
$progress-fill-color: #eff6f7;

// globe colors
$globe-contrast-color-0: #e3eef0;
$globe-contrast-color-1: #cce5ec;
$globe-contrast-color-2: #aed4e3;
$globe-contrast-color-3: #88b4c0;
$globe-contrast-color-4: #87a5b1;
$globe-border-color: #10101015;

// other colors
$shadow-color: #e5e5e5;
$border-color: #eeeeee;

$app-layout-color: #fafafa;

$text-color: #515751;
$star-color: #e6b74b;

$color-grey: #818181;
$color-grey-dark: #4e4e4e;

$color-neutral: #646464;

// # VIP colors
$vip-primary-color: #8c5825;
$vip-primary-color-light: #f1e5d9;
$vip-secondary-color: #cfc7c0;

$vip-grey: #535353;
$vip-black: #282828;
$vip-dark-grey: #424c50;

$vip-brown: #b57f43;
$vip-brown-light: #ead9c6;

$vip-border-color: #cecece;
$vip-background: #f7f6f5;

// TODO: the colors below should be refactored
$calendar-day-bg: #ecf5f8;
$star-input-color: #77a8c5;
$pagination-color: #e9eff3;
$acceptance-rate-color: #e9eff3;
$border-light-gray-color: #dedede;
$border-grey-color: #bebebe;
$color-border-logo: #f0f0f0;

$label-grey-color: #a0a0a0;
$saudi-arabia-map-bg-color: #bcdde9;

$color-warning: #c8b758;
$selected-node: #f5fafc;
$remove: #c85858;
$table-outline-color: #f5f5f5;
$document-backdrop-color: #1e1e1e;

// shadows
$default-shadow: 0 0.75rem 2rem $shadow-color;
$active-shadow: 0 0.85em 2.3em #58abc84d;
$light-shadow: #0000000a;
$dark-shadow: #0000000f;

$gold-bg: #cc9a271a;
$gold-color: #b9850d;
$silver-color: #818489;
$silver-bg: #8184891a;
$bronze-bg: #ffac7e1a;
$bronze-color: #946631;

$grey-100-color: #f5f7f7;
$grey-300-color: #c0cacf;
$grey-700-color: #363b40;
$grey-800-color: #18191a;
$grey-900-color: #070708;

$info-light-color: #e6ebfa;
$primary-ultra-light-color: #e6f3f4;
$primary-dark-color: #007785;

// Statistics Section
$statistics-section: (
  bg: #ebf5f8,
  line: #d7e8ee,
);

// Drop Area
$dnd: (
  bg: #f4f8f9,
  shadow: $default-shadow,
);

// Step Button
$step-btn: (
  shadow: 0 0.625rem 1.25rem $dark-shadow,
  shadow-selected: 0 0.3rem 1.25rem #58abc829,
);

// Button with Icon
$icon-btn: (
  bg: linear-gradient(180deg, $color-white 0%, darken($color-white, 1%) 100%),
  shadow: 0 0.625rem 1.25rem $dark-shadow,
  description: $text-color,
  text: $color-black,
);

// Selectable Button with Icon
$selectable-btn-icon: (
  bg: $color-white,
  border: rgba(#e9f0f2, 0.9),
  shadow: 0 0.625em 1.25em $dark-shadow,
  hover-bg: rgba(#e9f0f2, 0.9),
);

// Dropdown List
$dropdown-list: (
  bg: $color-white,
  shadow: 0 0.7em 1.4em #00000005,
);

// Dropdown Menu
$dropdown-menu: (
  bg: $color-secondary,
  shadow: 0 0.3em 0.85em $shadow-color,
);

// Input
$input: (
  bg: $color-white,
  color: $color-gray,
  shadow: 0 0.3em 0.85em $shadow-color,
  hover-shadow: 0 0.85em 2.3em #58abc84d,
  error: 0 0.85em 2.3em $remove,
);

// Offer Card
$offer-card: (
  bg: $color-white,
  shadow: 0 10px 20px #00000005,
  hover-shadow: 0 10px 20px #2d62871a,
);

// Step Indicator
$step-indicator: (
  line: $text-color,
  shadow: 0 3px 10px #2d628733,
);

// visa preview
$visa: (
  dark-blue: #14415a,
  lime: #2db473,
  turquoise: #148287,
  orange: #fab414,
);
