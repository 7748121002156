@use 'sass:math';

@import './global';
@import './colors';
@import './mixins';
@import './functions';

$subheader-arrow-height: 7.5rem;

%headerItem {
  column-gap: 0.5rem;
  font: 0.9375rem / 100% $main-font-bold;
  grid-auto-flow: column;
  align-items: center;
  cursor: pointer;
  display: grid;
}

%logo {
  display: flex;
  height: 3.25rem;
  width: 8.625rem;

  @include media(phone-small) {
    height: 2.375rem;
    width: 6.25rem;
  }
}

%titleSmall {
  font: 0.875rem / 1.7em $main-font-bold;
  text-transform: uppercase;
  color: $color-dark-blue;

  @include media(laptop) {
    font-size: 1.075rem;
  }

  @include media(desktop) {
    font-size: 1.275rem;
  }
}

%titleMedium {
  font: 2.25rem / 1.4em $main-font-bold;
  letter-spacing: -0.025em;
  text-align: center;

  @include media(phone-small) {
    font-size: 1.5rem;
  }

  @include media(laptop) {
    font-size: 2.75rem;
  }
}

%titleBig {
  font: 3.5rem / 1.2em $main-font-bold;
  letter-spacing: -0.025em;

  @include media(phone-large) {
    font-size: 2.25rem;
  }

  @include media(phone-small) {
    font-size: 1.5rem;
  }

  @include media(tablet) {
    font-size: 3rem;
  }

  @include media(laptop) {
    font-size: 4rem;
  }

  @include media(desktop) {
    font-size: 4.5rem;
  }
}

%lines {
  &::after,
  &::before {
    background: $color-dark-blue;
    position: absolute;
    width: 0.3125rem;
    margin: auto;
    content: '';
    bottom: 0;
    top: 0;
  }

  &::before {
    border-radius: 0 $border-radius $border-radius 0;
    left: 0;
  }

  &::after {
    border-radius: $border-radius 0 0 $border-radius;
    right: 0;
  }
}

%preloadAnimation {
  animation: preload $time-smooth * 1.5 ease-out infinite forwards;
  background: linear-gradient(
    to left,
    rgba($color-primary, 0.1) 20vw,
    rgba($color-primary, 0.25) 40vw,
    rgba($color-primary, 0.4) 50vw,
    rgba($color-primary, 0.25) 60vw,
    rgba($color-primary, 0.1) 80vw
  );
  background-size: 200vw 100%;
  background-position: 0% 0%;

  @keyframes preload {
    0% {
      background-position: 0 0%;
    }

    100% {
      background-position: 200vw 0%;
    }
  }
}

%vipPreloadAnimation {
  animation: preload $time-smooth * 1.5 ease-out infinite forwards;
  background: linear-gradient(
    to left,
    rgba($vip-primary-color, 0.1) 20vw,
    rgba($vip-primary-color, 0.25) 40vw,
    rgba($vip-primary-color, 0.4) 50vw,
    rgba($vip-primary-color, 0.25) 60vw,
    rgba($vip-primary-color, 0.1) 80vw
  );
  background-size: 200vw 100%;
  background-position: 0% 0%;

  @keyframes preload {
    0% {
      background-position: 0 0%;
    }

    100% {
      background-position: 200vw 0%;
    }
  }
}

%invisibleScroll {
  scrollbar-width: none; // Firefox

  &::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }
}

%noWrap {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

%card {
  display: grid;
  box-sizing: border-box;
  background-color: $color-white;
  box-shadow: 0 0.0625rem 0.625rem rgba($border-color, 0.25);
  transition: all $time-default linear;
  border: 0.0625rem solid $border-color;
  border-radius: $border-radius;

  @media (hover: hover) {
    &:hover {
      border-color: $color-secondary;
      box-shadow: 0 0.0625rem 0.625rem $color-tertiary;
    }
  }
}

%vipCard {
  @extend %card;
  padding: 1.5rem;
  border-radius: $vip-border-radius;
  border-color: transparent;

  @media (hover: hover) {
    &:hover {
      box-shadow: unset;
      border-color: transparent;
    }
  }
}

%vipCardShadow {
  box-shadow: 0 0.25rem 4rem 0 rgba(54, 59, 64, 0.1);
}

%cardTitle {
  font-size: 1.125rem;

  @include media(phone-large) {
    font-size: 0.875rem;
  }
}

%cardDescription {
  color: $color-neutral;
  font: 1rem / 1.75em $main-font-roman;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  overflow: hidden;
}

%cardButtonsContainer {
  grid-auto-flow: column;
  margin-top: auto;
  display: grid;
}

%cardButton {
  width: fit-content;
  height: fit-content;
  margin-inline-start: auto;
  padding-bottom: 0.34em;
  padding-top: 0.34em;
}

%nodeContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 1.25rem 0.75rem;

  @include media(phone-large) {
    gap: 0.75rem;
  }
}

%nodeElement {
  height: 2.8125rem;
  padding: 0 1.25rem;
  column-gap: 0.625rem;
  border-radius: 1.5625rem;
  font: 0.875rem / 1.5625em $main-font-roman;
  border: 0.0625rem solid $border-color;
  background-color: $color-white;
  transition: all $time-default;
  grid-auto-flow: column;
  box-sizing: border-box;
  align-items: center;
  color: $color-grey;
  width: fit-content;
  cursor: pointer;
  display: grid;

  @include media(phone-large) {
    font-size: 0.75rem;
    height: 2.3125rem;
  }

  svg {
    margin: 0 -0.3125rem;
    cursor: pointer;
  }
}

%buttonsContainer {
  margin-top: 2rem;

  @include media(phone-large) {
    margin-top: 1.5rem;
  }
}

%titleContainer {
  margin-bottom: 1rem;
  grid-auto-flow: column;
  align-items: baseline;
  width: fit-content;
  column-gap: 1rem;
  display: grid;

  @include media(phone-large) {
    justify-content: space-between;
    margin-bottom: 1rem;
    width: 100%;
  }
}

%viewAll {
  font-family: $main-font-bold;
  color: $color-primary;
  cursor: pointer;

  @include media(phone-large) {
    font-family: $main-font-roman;
    text-decoration: underline;
    font-size: 0.875rem;
  }
}

%statusBadge {
  @extend %noWrap;
  border-radius: 1.1875rem;
  font-family: $main-font-roman;
  border: 0.0625rem solid currentColor;
  width: fit-content;
  padding: 0 0.75rem;

  &.highlight {
    color: $color-primary;
    background-color: rgba($color-primary, 0.07);
  }

  &.warning {
    color: $color-warning;
    background-color: rgba($color-warning, 0.07);
  }

  &.success {
    color: $color-green;
    background-color: rgba($color-green, 0.07);
  }

  &.danger {
    color: $color-red;
    background-color: rgba($color-red, 0.07);
  }
}
