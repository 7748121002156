@import 'src/styles/variables/global';
@import 'src/styles/variables/colors';
@import 'src/styles/variables/elements';

.root {
  align-items: center;
  width: 40.75rem;
  display: flex;
  position: relative;

  @include media(phone-large) {
    width: calc(100vw - #{$container-indent-phone} * 2);
  }

  &::after,
  &::before {
    height: calc(100% - 1.5rem);
    width: 0.3125rem;
  }
}

.rootExtended {
  @extend %lines;
  @extend .root;
}

.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 2rem 0;
  width: 100%;
  transition: all $time-smooth linear;
}

.icon {
  width: 5.4rem;
  height: 5.4rem;
}

.description {
  max-width: calc(100% - 5rem);
  margin: 0.715rem 0 2rem;
  font: 1.3rem / 2.275rem $tahoma-regular;
  padding: 0 4rem;
  text-align: center;
  word-break: break-word;
  min-height: 3rem;

  @include media(phone-large) {
    margin-bottom: $phone-block-indent;
    max-width: calc(100% - 3rem);
    padding: 0 1rem;
  }
}

.descriptionNew {
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: $color-dark-blue;
  width: 100%;
}

.title {
  margin-top: 0.8rem;
  font: 1.8rem / 2.8rem $main-font-bold;
  text-align: center;
  padding: 0 2rem;

  &.success {
    color: $color-green;
  }

  &.info {
    color: $color-dark-blue;
  }
}

.actionsContainer {
  display: grid;
  column-gap: 2rem;
  grid-auto-flow: column;
  justify-content: center;
  width: 100%;
}
