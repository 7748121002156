@import 'src/styles/variables/global';
@import 'src/styles/variables/mixins';

.root {
  display: grid;
  margin: 0 auto;
  row-gap: 1.5rem;
  text-align: center;
  grid-template-columns: 100%;
  width: $container-tablet;

  @include media(phone-large) {
    width: $container-phone;
  }

  @include media(desktop) {
    width: $container;
  }
}

.title {
  margin-top: 0.8rem;
}

.description {
  font: 1.3rem / 2.275rem $tahoma-regular;
  word-break: break-word;
  margin-bottom: 1rem;
}

.referenceNumber {
  @extend .description;
  font: 1rem / 1.1875rem $tahoma-regular;
}

.rowContainer {
  margin: auto;
}

.icon {
  height: 5.4rem;

  @include media(phone-large) {
    height: 3.5rem;
  }
}

.offlineIcon {
  @extend .icon;
  @include media(phone-large) {
    width: 10rem;
  }
}
