@import './functions';
@import './global';
@import './colors';

html {
  height: 100%;
  text-size-adjust: 100%;
  font-size: $browser-context;
  min-height: vh-without-url-bar(100);
  transition: filter $time-fast ease-in-out;
  background-color: $color-white;
  overflow: hidden;

  body {
    margin: 0;
    height: 100%;
    min-width: 320px;
    overflow: hidden;
    scroll-behavior: smooth;
    font-size: $browser-context;
    -webkit-tap-highlight-color: $highlight;
    -webkit-font-smoothing: antialiased;

    ::selection {
      color: $color-white;
      background-color: $color-primary;
    }
  }

  p,
  h1,
  h2,
  h3 {
    margin: 0;
    font-weight: initial;
  }

  a {
    text-decoration: none;
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style-type: none;
    }
  }

  nav li {
    display: inline-block;
  }

  input,
  button {
    border: 0;
    outline: none;
    cursor: pointer;
  }
}

@media only print {
  html,
  body {
    overflow: visible;
    -webkit-print-color-adjust: exact;
  }
}
