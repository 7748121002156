@import 'src/styles/variables/colors';
@import 'src/styles/variables/global';
@import 'src/styles/variables/mixins';

@keyframes contentMount {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

@keyframes unfoldIn {
  0% {
    transform: scaleY(0.005) scaleX(1);
  }

  100% {
    transform: scaleY(1) scaleX(1);
  }
}

:global {
  #modalRoot {
    // maximum ten modals
    @for $i from 1 through 10 {
      > div:nth-child(#{$i}) {
        // element responsible for the root of modals
        > div {
          z-index: #{map-get($z-index, modal) + ($i + 5) * 2};
        }
      }
    }
  }
}

.root {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($color: $color-white, $alpha: 0.9);
}

.rootNew {
  background-color: rgba($color: $color-black, $alpha: 0.4);
}

.closeBtn {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

.icon {
  path {
    fill: $color-grey-dark;
  }
}

.content {
  position: relative;
  background-color: $color-white;
  border-radius: 1rem;
  box-shadow: $default-shadow;
  max-width: $container;
  animation: contentMount $time-default;

  @include media(phone-large) {
    max-width: $container-phone;
  }

  @include media(tablet) {
    max-width: $container-tablet;
  }

  @include media(desktop) {
    max-width: 70vw;
  }
}

.contentNew {
  box-shadow: unset;
}

.rootShowActive {
  @extend .root;
  animation: unfoldIn $time-default;
}

.rootHideActive {
  @extend .root;
  animation: unfoldIn $time-default;
  animation-direction: reverse;
}

[dir='rtl'] {
  .closeBtn {
    left: 1rem;
    right: unset;
  }
}
