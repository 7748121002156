// Frutiger
@font-face {
  font-family: 'Frutiger Light';
  src: local('Frutiger'), url('../../fonts/Frutiger-LT-Arabic-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Frutiger Roman';
  src: local('Frutiger'), url('../../fonts/Frutiger-LT-Arabic-Roman.ttf') format('truetype');
}

@font-face {
  font-family: 'Frutiger Bold';
  src: local('Frutiger'), url('../../fonts/Frutiger-LT-Arabic-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Frutiger Black';
  src: local('Frutiger'), url('../../fonts/Frutiger-LT-Arabic-Black.ttf') format('truetype');
}

// Tahoma
@font-face {
  font-family: 'Tahoma Regular';
  src: local('Tahoma'), url('../../fonts/Tahoma-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Tahoma Bold';
  src: local('Tahoma Bold'), url('../../fonts/Tahoma-Bold.ttf') format('truetype');
}
