@import 'src/styles/variables/global';
@import 'src/styles/variables/colors';
@import 'src/styles/variables/mixins';

.root {
  color: $color-black;
  font-family: $main-font-bold;
  letter-spacing: -0.025em;
  margin-bottom: 0.325em;
  line-height: 1.3em;

  &.darkBlue {
    color: $color-dark-blue;
  }

  &.darkGray {
    color: $text-color;
  }

  &.primary {
    color: $color-primary;
  }

  &.black {
    color: $vip-black;
  }
}

.title {
  font-size: 1.6rem;
  line-height: 1.4em;
  letter-spacing: unset;
  margin-bottom: 0;

  @include media(phone-large) {
    font-size: 1.5rem;
  }

  @include media(phone-small) {
    font-size: 1.2rem;
  }

  @include media(desktop) {
    font-size: 2.1rem;
  }
}

.subtitle {
  font-size: 1.35rem;
  letter-spacing: unset;
  margin-bottom: 0;

  @include media(phone-small) {
    font-size: 1rem;
  }

  @include media(desktop) {
    font-size: 1.55rem;
  }
}

.h1 {
  font-size: 3rem;

  @include media(phone-large) {
    font-size: 2.1rem;
  }

  @include media(phone-small) {
    font-size: 1.6rem;
  }

  @include media(desktop) {
    font-size: 4rem;
  }
}

.h2 {
  font-size: 2.8rem;

  @include media(phone-large) {
    font-size: 2rem;
  }

  @include media(phone-small) {
    font-size: 1.5rem;
  }

  @include media(desktop) {
    font-size: 3.8rem;
  }
}

.h3 {
  font-size: 2.2rem;

  @include media(phone-large) {
    font-size: 1.9rem;
  }

  @include media(phone-small) {
    font-size: 1.4rem;
  }

  @include media(desktop) {
    font-size: 3.2rem;
  }
}
